import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocalPhone, MdLocationOn, MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Paintball club SplatterZone
			</title>
			<meta name={"description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:title"} content={"Paintball club SplatterZone"} />
			<meta property={"og:description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride7" />
		</Components.Header>
		<Components.Hero background="linear-gradient(320deg,rgba(0,0,0,0) 0%,rgba(0, 0, 0, 0.76) 72.4%),--color-darkL2 url(https://uploads.quarkly.io/65e71741926d910020edb149/images/3-3.jpg?v=2024-03-06T10:22:29.442Z) center/cover">
			<Override slot="text1">
				Contacts
			</Override>
			<Override slot="text" display="none" />
		</Components.Hero>
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Contacts-2">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="center"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				Contact information
			</Text>
			<Text
				margin="0px 20% 50px 20%"
				font="--base"
				lg-margin="0px 0 50px 0px"
				text-align="center"
				sm-margin="0px 0 35px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				To make a reservation, please contact the phone number +61296790011
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				align-items="center"
				lg-align-items="flex-start"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdLocalPhone}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-primary"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" align-self="center">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							+61296790011
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-primary"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							5 Curtis Rd, Vineyard NSW 2765, Australia
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdEmail}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-primary"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" align-self="center">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							info@zenztrove.com
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde6288e2e8e002178b6f4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});